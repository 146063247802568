<template>
  <div class="container">
    <!-- Language -->
    <div style="position: fixed; right: 20px; top: 20px">
      <div class="btn-login" style="background-color: #00BCD4; border-radius: 5px;">
      </div>
    </div>
    <!-- Login form -->
    <div class="login-container">
      <div style="text-align: left; width: 350px">
        <span style="font-size: 20px;">{{ $t('register.password_reset_done_title') }}</span>
        <span style="display: block; margin-top: 15px; margin-bottom: 15px; color: #707070">
            {{ $t('register.password_reset_done_c1') }}</span>
        <span style="color: #707070">{{ $t('register.password_reset_done_c2') }}</span>
      </div>
    </div>
    <snack-bar v-for="snackbar in GET_SNACK_BAR" :key="snackbar.id" :item="snackbar"></snack-bar>
    <loading-bar :isLoading="isLoading"></loading-bar>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    isLoading: false,
    locale: 'vi',
  }),
  computed: {
    ...mapGetters(["GET_SNACK_BAR"])
  },
  methods: {
    changeLanguage: function() {
      let locale = this.$store.state.language.selectedLocale;
      if (locale == "en") {
        this.$i18n.locale = "vi";
        this.locale = "vi";
      } else {
        this.$i18n.locale = "en";
        this.locale = "en";
      }
      this.$store.dispatch("changeLanguage", this.locale);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../style/color.scss";
.login-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn-login {
  background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.3, $light-primary),
    color-stop(0.7, $primary)
  );
  border-radius: 5px;
}
</style>